import { FC } from 'react';
// Interfaces
import { IconSvgProps } from '@utmb/types/icons';

const Burger: FC<IconSvgProps> = ({ color, clss }) => {
    return (
        <svg className={clss} viewBox="0 0 22 22" width="22" height="22" focusable="false" aria-hidden="true">
            <g fill="none" fillRule="evenodd">
                <path d="M1.5 16.5h19m-19-7h19m-19-7h19" stroke={color} strokeWidth="1.5" />
            </g>
        </svg>
    );
};

export default Burger;
